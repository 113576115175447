import {
  AnalyticsUserAction,
  CommonUserActionEventProperties,
  CountryType,
  Options,
  UserSignedUpEventProperties,
  UserUpdatedEventProperties,
} from '@segment/analytics-next'

import { generateCustomerId } from '@/helpers/AnalyticsSegmentHelper'
import { useAnalytics } from './AnalyticsHook'

type UserActionInput = {
  country?: CountryType
  success?: boolean
  updatedFields?: string[]
  email?: string
}

//! Since there will be no complex data processing, all types and calls will be performed here
export const useAnalyticsUserAction = (action: AnalyticsUserAction) => {
  const analytics = useAnalytics()

  const userPerformAction = (actionInput: UserActionInput = {}) => {
    const { country = 'AU', success = true, updatedFields = [], email } = actionInput
    const context: Options = {}

    // Ensure to tie the user event to the correct user by using customer ID
    if (email) {
      const customerId = generateCustomerId(email)
      context.traits = { customer_id: customerId }
    }

    switch (action) {
      case 'Signed In': {
        const eventProperties: CommonUserActionEventProperties = { success }
        analytics.trackEvent({ object: 'User', action: action, properties: eventProperties, context })
        return
      }
      case 'Signed Out': {
        analytics.trackEvent({ object: 'User', action: action, properties: {}, context })
        return
      }
      case 'Signed Up': {
        const eventProperties: UserSignedUpEventProperties = { country }
        analytics.trackEvent({ object: 'User', action, properties: eventProperties, context })
        return
      }
      case 'Updated': {
        const eventProperties: UserUpdatedEventProperties = {
          success,
          updated_fields: updatedFields,
        }
        analytics.trackEvent({ object: 'User', action, properties: eventProperties, context })
        return
      }
      default:
        return
    }
  }

  return userPerformAction
}
