import { Spacing, Body } from '@big-red-group/storefront-common-ui'
import { Wrapper, Tab } from './LoginCreateTabs.styles'
import { useRouter } from 'next/router'

type TabType = {
  text: string
  url: string
}

const SocialSignIn: React.FC<{ activeTab: number }> = ({ activeTab }) => {
  const router = useRouter()

  const tabs: TabType[] = [
    {
      text: 'Sign in',
      url: '/en/login',
    },
    {
      text: 'Create account',
      url: '/en/signup',
    },
  ]

  const handleTabClick = (url: string) => {
    if (url == router.asPath) return

    window.location.href = url
  }

  return (
    <>
      <Wrapper>
        {tabs.map(({ text, url }, index) => (
          <Tab active={activeTab === index} onClick={() => handleTabClick(url)} key={index}>
            <Body size="M" weight="regular">
              {text}
            </Body>
          </Tab>
        ))}
      </Wrapper>
      <Spacing size="XXL" />
    </>
  )
}

export default SocialSignIn
