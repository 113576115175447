import Container from './Container'
import { Spacing, FlexibleHeading as Heading, Body } from '@big-red-group/storefront-common-ui'
import Icon, { IconName } from './Icons'
import { PromisePointWrapper, PromisePoint } from './PromisePoints.styles'

const promisePoints: { text: string; icon: IconName }[] = [
  {
    text: 'Never lose a voucher again.',
    icon: 'giftVoucher',
  },
  {
    text: 'Manage or find important details for your upcoming adventures.',
    icon: 'mapBrochure',
  },
  {
    text: 'Exchange or redeem your adventure vouchers.',
    icon: 'tickets',
  },
]

const PromisePoints: React.FC = () => {
  return (
    <Container>
      <Heading alignment="center" as="h2" size="M">
        Why create an account?
      </Heading>
      <Spacing size="XXL" />
      <PromisePointWrapper>
        {promisePoints.map(({ text, icon }, index) => {
          return (
            <PromisePoint key={index}>
              <Icon name={icon} stroke="black" />
              <Body size="M" weight="regular">
                {text}
              </Body>
            </PromisePoint>
          )
        })}
      </PromisePointWrapper>
    </Container>
  )
}

export default PromisePoints
