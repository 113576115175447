import { BrandColor } from '@big-red-group/storefront-common-ui'
import styled from 'styled-components'

export const Wrapper = styled.div<{ imageUrl: string; bgColor?: BrandColor }>`
  background: url(${({ imageUrl }) => imageUrl});
  background-color: var(--${({ bgColor }) => (bgColor ? bgColor : 'brand-secondary')});
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
`
