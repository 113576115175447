import styled from 'styled-components'

export const SocialIntegrations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
`

export const Social = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--grey-10);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: var(--brand-accent-secondary-dark);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`
