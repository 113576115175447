import styled from 'styled-components'
import { BREAKPOINT_SMALL, BREAKPOINT_MEDIUM } from '@/constant/ui'

export const PromisePointWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 33%));
  gap: 24px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    grid-template-columns: repeat(1, minmax(0, 100%));
    gap: 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    grid-template-columns: 100%;
    gap: 40px;
  }
`

export const PromisePoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    width: 96px;
    height: 96px;
    margin-bottom: 24px;

    path {
      stroke-width: 0;
    }
  }
`
