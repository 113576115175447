import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  & > label {
    font-family: var(--font-regular);
    color: var(--black);
    display: block;
    font-size: 14px;

    &.required:after {
      content: ' *';
      color: red;
    }
  }

  & > input {
    width: 100%;
  }
`

export const PrefixInputWrapper = styled.div`
  position: relative;

  & > input {
    width: 100%;
    padding-left: calc(84px + 16px);
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const FieldDesc = styled.div`
  font-weight: var(--font-regular);
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--grey-100);
`

export const CharCount = styled.span<{ reachedMax: boolean }>`
  font-size: 13px;
  color: var(--text-primary-light);

  ${({ reachedMax }) =>
    reachedMax &&
    `
    color: var(--ui-red);
  `}
`
