import { Wrapper } from '@/components/BackgroundImage.styles'
import { BrandColor } from '@big-red-group/storefront-common-ui'

const BackgroundImage: React.FC<{ children: React.ReactNode; bgColor?: BrandColor; imageUrl?: string }> = ({
  children,
  imageUrl = '',
  bgColor,
}) => {
  return (
    <Wrapper imageUrl={imageUrl} bgColor={bgColor}>
      {children}
    </Wrapper>
  )
}

export default BackgroundImage
