import Link from 'next/link'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  button {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
`

export const FormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`

export const InputWrapper = styled.div`
  & > label {
    font-family: var(--font-regular);
    color: var(--black);
    display: block;
    margin-bottom: 8px;
    font-size: 13px;
  }

  & > input {
    width: 100%;
  }
`

export const ForgotPasswordLink = styled(Link)`
  color: var(--text-primary-light);
  font-family: var(--font-regular);
  font-size: 14px;
  margin: 15px 0;
  width: 144px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

export const GoBack = styled.div`
  color: var(--black);
  font-family: var(--font-medium);
  font-size: 14px;
  width: 90px;
  margin: 10px auto;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover span {
    text-decoration: underline;
  }
`
export const IconWrapper = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: var(--grey-25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--text-primary-light);
  }
`
export const InputGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  & > label {
    font-family: var(--font-regular);
    color: var(--black);
    display: block;
    font-size: 14px;

    &.required:after {
      content: ' *';
      color: red;
    }
  }

  & > input {
    width: 100%;
  }
`
export const FieldWrap = styled.div`
  position: relative;

  > input {
    width: 100%;
  }
`
