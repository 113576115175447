export const createPageTitle = (titlePrefix: string): string => {
  return `${titlePrefix} | Adrenaline`
}

export const renderOpenGraph = (title: string, desc: string, image: string) => {
  return (
    <>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} name="description" />
      <meta property="og:image" content={image} />
    </>
  )
}
