import { useFormik } from 'formik'
import { Spacing } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'
import TextField from './TextField'
import InputError from './InputError'
import { Wrapper, FormFields, ForgotPasswordLink, IconWrapper, InputGroupWrapper, FieldWrap } from './LoginForm.styles'
import { useRouter } from 'next/router'
import { InputWrapper } from '@/components/Input.styles'
import { signIn } from 'next-auth/react'
import { useNotification } from '@/hooks/NotificationHook'
import * as Yup from 'yup'
import { useState } from 'react'
import Icon from './Icons'
import { VALID_EMAIL_REGEX } from '@big-red-group/storefront-common-checkout'
import { DEFAULT_LANGUAGE } from '@/helpers/UrlHelper'

type LoginFormProps = {
  onGoBack: () => void
  signInSegmentHandler: (args: { success?: boolean; email?: string }) => void
}

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(VALID_EMAIL_REGEX, 'Please enter a valid email address'),
  password: Yup.string().required('Please enter a password'),
})

const LoginForm: React.FC<LoginFormProps> = ({ onGoBack, signInSegmentHandler }) => {
  const notification = useNotification()
  const router = useRouter()

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const { lang } = router.query

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      const res = await signIn('credentials', {
        username: email,
        password,
        redirect: false,
      })
      if (res?.error) {
        signInSegmentHandler({ success: false })
        notification('Error signing in. Try again later.', 'Sign in', 'error')
        return
      }
      signInSegmentHandler({ email })
      notification('Signed in!', 'Success', 'success')
      const query = { ...router.query }
      delete query.lang
      router.push(
        router.query?.callBack
          ? `${window.location.origin}/en/${router.query?.callBack}`
          : `${window.location.origin}/en`
      )
    },
  })

  return (
    <Wrapper>
      <FormFields>
        <InputWrapper>
          <label>Email</label>
          <TextField
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && formik.isValid && !formik.isSubmitting) {
                formik.handleSubmit()
              }
            }}
            error={!!formik.touched.email && !!formik.errors.email}
          />
          {formik.touched.email && formik.errors.email && (
            <InputError dataTestId="email-error-message">{formik.errors.email}</InputError>
          )}
        </InputWrapper>
        <InputWrapper>
          <InputGroupWrapper>
            <label>Password</label>
            <FieldWrap>
              <TextField
                name="password"
                type={passwordShown ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.password && !!formik.errors.password}
                onKeyDown={(e) => {
                  if (e.code === 'Enter' && formik.isValid && !formik.isSubmitting) {
                    formik.handleSubmit()
                  }
                }}
                data-cy="password-input"
              />
              <IconWrapper onClick={togglePasswordVisiblity}>
                {passwordShown ? (
                  <Icon name="eyeSlash" width={22} height={22} />
                ) : (
                  <Icon name="eye" width={20} height={20} />
                )}
              </IconWrapper>
            </FieldWrap>
          </InputGroupWrapper>
          {formik.touched.password && formik.errors.password && (
            <InputError dataTestId="email-error-message">{formik.errors.password}</InputError>
          )}
        </InputWrapper>
      </FormFields>
      <Spacing size="L" />
      <ForgotPasswordLink
        onClick={() => {
          router.push(`/${router.query?.lang ?? DEFAULT_LANGUAGE}/reset-password`)
        }}
        href="#"
      >
        Forgotten password?
      </ForgotPasswordLink>
      <Spacing size="L" />
      <Button
        size="L"
        onClick={formik.handleSubmit}
        disabled={formik.isSubmitting}
        type="submit"
        dataTestId="login-button"
      >
        {formik.isSubmitting ? 'Loading...' : 'Sign in'}
      </Button>
      <Spacing size="M" />
    </Wrapper>
  )
}

export default LoginForm
