import Icon from './Icons'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import { SocialIntegrations, Social } from './SocialSignIn.styles'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { BuiltInProviderType } from 'next-auth/providers'

const SocialSignIn: React.FC = () => {
  const router = useRouter()
  const lang = router.query.lang

  const handleSocialLogin = (provider: BuiltInProviderType) => {
    signIn(provider, { callbackUrl: `/${lang}/account` })
  }

  return (
    <>
      <SocialIntegrations>
        <Social>
          <Icon name="google" onClick={() => handleSocialLogin('google')} />
        </Social>
        <Social>
          <Icon name="facebookLetter" onClick={() => handleSocialLogin('facebook')} />
        </Social>
        {/* <Social>
          <Icon name="apple" onClick={() => console.log('click')} />
        </Social> */}
      </SocialIntegrations>
      <Spacing size="XL" />
      <Heading as="span" size="XXS" alignment="center" weight="regular">
        or continue with email
      </Heading>
      <Spacing size="XL" />
    </>
  )
}

export default SocialSignIn
