import Container from './Container'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import LoginForm from './LoginForm'
import SocialSignIn from './SocialSignIn'
import LoginCreateTabs from './LoginCreateTabs'
import PromisePoints from './PromisePoints'
import { Wrapper, FormWrapper } from './LoginPageTemplate.styles'
import { createPageTitle } from '@/helpers/PageTitleHelper'
import Image from 'next/image'
import Head from 'next/head'
import BackgroundImage from './BackgroundImage'
import { useEffect, useMemo, useState } from 'react'
import { CheckoutStep } from '@/types'
import { REDEMPTION_STEPS } from '@/constant/voucher'
import { useRouter } from 'next/router'
import ProgressSteps from './ProgressSteps'
import { useAnalyticsUserAction } from '@/hooks/AnalyticsUserHook'

const LoginPageTemplate = () => {
  const router = useRouter()
  const [isLoginSelected, setIsLoginSelected] = useState(false)
  const trackUserSignedIn = useAnalyticsUserAction('Signed In')
  const [isRedemptionProcess, setIsRedemptionProcess] = useState(false)

  const redemptionSteps: CheckoutStep[] = useMemo(
    () =>
      REDEMPTION_STEPS.map(({ label, icon }, index) => ({
        label: label,
        active: index === 1,
        complete: index === 0,
        icon: icon,
      })),
    []
  )

  useEffect(() => {
    if (router.query?.redeem) {
      setIsRedemptionProcess(true)
    }
  }, [])

  const handleSignInAction = ({ success, email }: { success?: boolean; email?: string }) => {
    trackUserSignedIn({ success, email })
  }

  const handleGoBack = () => {
    setIsLoginSelected(false)
  }

  return (
    <>
      <Head>
        <title>{createPageTitle('Sign in')}</title>
      </Head>
      {isRedemptionProcess && <ProgressSteps customSteps={redemptionSteps} />}
      <BackgroundImage>
        <Spacing size="XXL" />
        <Container>
          <Wrapper>
            <Image
              alt="Login page image"
              src="https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/153785.jpg"
              width={0}
              height={0}
              sizes="100vw"
              style={{ width: '50%', height: 'auto' }}
            />
            <FormWrapper>
              <LoginCreateTabs activeTab={0} />
              <Heading size="M" as="h2" alignment="center">
                Sign in to unlock the best of Adrenaline
              </Heading>
              <Spacing size="L" />
              <SocialSignIn />
              <LoginForm onGoBack={handleGoBack} signInSegmentHandler={handleSignInAction} />
            </FormWrapper>
          </Wrapper>
        </Container>
        <Spacing size="XXL" />
      </BackgroundImage>
      <Spacing size="XXXL" />
      <PromisePoints />
      <Spacing size="XXXL" />
    </>
  )
}

export default LoginPageTemplate
