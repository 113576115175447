import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  max-width: 1100px;
  margin: 0 auto;

  & > img {
    object-fit: cover;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    margin: 0 8px 50px 8px;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;
  width: 50%;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 30px;
    width: 100%;
  }
`
