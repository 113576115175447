import { Z_INDEX } from '@/constant/ui'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 1px solid var(--grey-10);
`
export const Tab = styled.div<{ active: boolean }>`
  width: 50%;
  flex-shrink: 0;
  padding-bottom: 14px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: ${Z_INDEX.medium};
  top: 1px;

  &:hover {
    opacity: 0.8;
  }

  ${({ active }) =>
    active &&
    `
      border-bottom-color: var(--brand-accent-secondary);
      
      p {
        font-family: var(--font-semibold);
      }
    `}
`
